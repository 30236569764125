<template>
  <div class="layout">
    <router-view />
    <TabBar :tabData="tabbarList" @change="onChange" :defaultActive="active" />
    <!-- <main>
      
    </main>
    <div class="bottom"> -->
      
      <!-- <van-tabbar :placeholder="true" :safe-area-inset-bottom="true" v-model="active" @change="onChange">
        <van-tabbar-item replace icon="column">首页</van-tabbar-item>
        <van-tabbar-item replace icon="search">二手车</van-tabbar-item>
        <van-tabbar-item replace icon="manager">个人中心</van-tabbar-item>
      </van-tabbar> -->
    <!-- </div> -->
    
  </div>
</template>

<script>
import TabBar from '@/components/tabBar'
export default {
  name:'Layout',
  components: {
    TabBar
  },
  data() {
    return {
      active: 0,
      tabbarList: [
      {
          title: 'ངོས་དང་པོ།',
          title1: '首页',
          normal: require("../assets/image/home.png"),
          active: require("../assets/image/homeselect.png")
        },
        {
          title: 'དཔེ་ཁང་།',
          title1: '书城',
          normal: require("../assets/image/car.png"),
          active: require("../assets/image/carselect.png")
        },
        {
          title: 'རང་ངོས།',
          title1: '我的',
          normal: require("../assets/image/my.png"),
          active: require("../assets/image/mySelect.png")
        }
      ]
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    const path = this.$route.path
    if(path === '/') {
      this.active = 0
    } else if (path === '/secondHandCar') {
      this.active = 1
    } else if (path === '/self') {
      this.active = 2
    }
  },
  mounted() {
    
  },
  methods: {
    onChange(index) {
      console.log(index, this.active, 'index')
      if (index !== this.active) {
        localStorage.getItem('login', )
        if (index === 0) {
          this.$router.push('/')
          this.active = index
        } else if (index === 1) {
          this.$router.push('/secondHandCar')
          this.active = index
        } else {
          this.$router.push('/self')
          this.active = index
        }
      }
      
    }
  }
}
</script>

<style scoped lang="scss">
.layout{
  display: flex;
  flex-direction: column;
  width: 375px;
  height: 100%;
  background: linear-gradient(90deg, #F4F8FB 0%, #FAF6F5 100%);
  main{
    flex: 1;
    overflow-y: scroll;
  }
}
</style>
