<template>
  <div class="tabbar">
    <van-tabbar v-model="active" fixed router >
      <van-tabbar-item
        v-for="(item, index) in tabData"
        :icon="item.icon"
        :to="item.path"
        :key="index"
        @click="handleChange(index)">
        <p :class="defaultActive === index ? active:''"><span>{{item.title}}</span>{{item.title1}}</p>
        <!-- <p :class="defaultActive === index ? active:''" style="text-align: center;">{{item.title}}</p>
        <p :class="defaultActive === index ? active:''" style="text-align: center;margin-top: 4px;">{{item.title1}}</p> -->
        <template slot="icon" slot-scope="props">
          <img :src="props.active ? item.active : item.normal">
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
 
<script>
export default {
  name: 'TabBar',
  props: {
    defaultActive: {
      type: Number,
      default: 0
    },
    tabData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active: this.defaultActive
    }
  },
  methods: {
    handleChange(val) {
      this.$emit('change', val);
    }
  }
}
</script>
 
<style scoped lang="scss">
.tabbar{
  width: 100%;
  height: 70px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
}
.van-tabbar--fixed{
  position: static;
  height: 100%;
}
.van-tabbar-item{
  height: 49px;
  margin-top: 6px;
}
::v-deep .van-tabbar-item__icon{
  margin-bottom: 0;
}
.van-tabbar-item__icon img {
  width: 25px;
  height: 25px;
}
.van-tabbar-item__text p{
  font-size: 12px;
  color: #999999;
}
.van-tabbar-item__text span{
  line-height: 20px;
}
.active_tab img {
  width: 3rem;
  height: 3rem;
}
.van-tabbar-item--active p{
  font-size: 12px;
  color: #1677FF;
}
</style>